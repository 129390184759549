import React, { useContext } from "react";
import { MdPowerSettingsNew } from "react-icons/md";
import { useHistory } from "react-router-dom";

import { Context } from "../context/context";
import DealerLogo from "../assets/images/inner.png";
import FranchiseLogo from "../assets/images/bmw-logo.png";
import strapLine from "../assets/images/vw-logo.png";

const Menu = () => {
  const history = useHistory();

  const {
    state: {
      auth: { isAuthenticated },
      data: { details },
    },
    dispatch,
  } = useContext(Context);

  return (
    <header>
      <nav className="navbar navbar-expand-lg py-0">
        <div className="container mt-2 py-lg-0">
          <div className="mx-auto mb-4 mx-md-0 my-2">
            <img src={DealerLogo} className="img-fluid" alt="main-logo" height={50} />
          </div>
          {isAuthenticated && (
            <div className="mx-auto mx-md-0 text-center">
              <div className="logout d-md-inline mr-0 mr-md-4">
                Welcome, {details.title} {details.firstName} {details.lastName}
              </div>
              <button
                onClick={() => {
                  dispatch({
                    type: "LOGOUT",
                    payload: {},
                  });
                  history.push("/");
                }}
                className="btn btn-sm btn-outline-primary mb-0"
                type="button"
              >
                <MdPowerSettingsNew className="mi-18" />
                Logout
              </button>
            </div>
          )}
            {!isAuthenticated && (
                <div className="mx-auto mx-md-0 text-center">
                  <div className="logout d-md-inline mr-0 mr-md-4">

                  </div>

                </div>
            )}
        </div>

      </nav>
    </header>
  );
};

export default Menu;
