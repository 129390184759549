import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import getSize from "viewport-size";
import { MdWarning } from "react-icons/md";

import { Context } from "../context/context";
import EventImage from "../assets/images/event-image.png";

const Details = () => {
  let history = useHistory();
  const {
    state: {
      data: { details },
    },
    dispatch,
  } = useContext(Context);

  const [detailsFields, setDetailsFields] = useState(JSON.parse(localStorage.getItem("details")) || details);
  const [mandatoryFields, setMandatoryFields] = useState({ title: false, firstName: false, lastName: false, emailAddress: false, mobile: false });
  const [showErrors, setShowErrors] = useState(false);

  const { title, firstName, lastName, emailAddress, mobile, phone } = detailsFields;

  const navigateNext = (slug) => {
    const { title, firstName, lastName, emailAddress, mobile } = mandatoryFields;
    if (title && firstName && lastName && emailAddress && mobile) {
      // Set the layout to completed and then
      dispatch({ type: "SET_LAYOUT_VISITED", slug: "details" });
      history.push(slug);
    } else {
      //set page error  to true
      setShowErrors(true);
    }
  };

  const navigateBack = (slug) => {
    // First set the layout to completed and then
    // dispatch({ type: "SET_LAYOUT_VISITED", slug: "welcome" });
    history.push(slug);
  };

  const handleInputChange = (event) => {
    dispatch({ type: "UPDATE_DETAIL", data: { name: event.target.name, value: event.target.value } });
  };

  // This useEffect will run on first load to check what is in localstorage and populate our context with it
  useEffect(() => {
    const data = localStorage.getItem("details");
    if (data) {
      dispatch({ type: "UPDATE_ALL_DETAILS", data: JSON.parse(data) });
      setDetailsFields(details);
    }

    // Scroll to top on mobile screens only page load
    if (getSize.getWidth() < 575) {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 420,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    } else {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect will set the data into localstorage on selection change
  useEffect(() => {
    const { title, firstName, lastName, emailAddress, mobile } = mandatoryFields;

    setDetailsFields(details);
    localStorage.setItem("details", JSON.stringify(details));

    if (title && firstName && lastName && emailAddress && mobile) {
      // Set the layout to completed and then
      setShowErrors(false);
    }
  }, [details, mandatoryFields]);

  // This useEffect will check whether any of the fields has been selected and set the button visibility
  useEffect(() => {
    // const { title, firstName, lastName, emailAddress, mobile } = mandatoryFields;
    const enteredFields = {
      title: false,
      firstName: false,
      lastName: false,
      emailAddress: false,
      mobile: false,
      phone: false,
    };

    for (const [key] of Object.entries(enteredFields)) {
      if (detailsFields[key].length === 0) {
        enteredFields[key] = false;
      } else {
        enteredFields[key] = true;
      }
    }

    setMandatoryFields((prevState) => {
      return { ...prevState, title: enteredFields["title"], firstName: enteredFields["firstName"], lastName: enteredFields["lastName"], emailAddress: enteredFields["emailAddress"], mobile: enteredFields["mobile"] };
    });
  }, [detailsFields]);

  return (
    <div className="card border-0 mb-5 my-md-0 mb-0 mb-md-5">
      <div className="card-body">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-8 order-2 order-md-1 text-center text-md-left my-4 my-md-0">
            <h1 className="page-title mb-0">Please confirm your details</h1>
          </div>
          <div className="col-8 col-md-4 order-1 order-md-2">

          </div>
        </div>
        <div className="row text-center text-md-left mt-3">
          <div className="col-12 col-md-6">
            <div className="row justify-content-center justify-content-md-start">
              <div className="col-6 col-lg-3 mb-2">
                <label>
                  <strong>Title</strong>
                </label>

                {showErrors && !mandatoryFields.title && (
                  <div className="alert alert-danger mt-1" role="alert">
                    <MdWarning className="mi-18" /> Title is required
                  </div>
                )}
                <select className="select" defaultValue={title} name="title" onChange={(event) => handleInputChange(event)}>
                  <option value="" defaultValue="selected" disabled>
                    Choose an option
                  </option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Miss">Miss</option>
                  <option value="Master">Master</option>
                  <option value="Dr">Dr</option>
                </select>
              </div>
            </div>

            <div className="row justify-content-center justify-content-md-start">
              <div className="col-12 col-sm-7 col-lg-6 my-2">
                <label>
                  <strong>First Name</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={firstName}
                  name="firstName"
                  onChange={(event) => {
                    handleInputChange(event);
                  }}
                />
                {showErrors && !mandatoryFields.firstName && (
                  <div className="alert alert-danger mt-1" role="alert">
                    <MdWarning className="mi-18" /> First Name is required
                  </div>
                )}
              </div>
              <div className="col-12 col-sm-7 col-lg-6 my-2">
                <label>
                  <strong>Surname</strong>
                </label>

                <input
                  type="text"
                  className="form-control"
                  defaultValue={lastName}
                  name="lastName"
                  onChange={(event) => {
                    handleInputChange(event);
                  }}
                />

                {showErrors && !mandatoryFields.lastName && (
                  <div className="alert alert-danger mt-1" role="alert">
                    <MdWarning className="mi-18" /> Last Name is required
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-9">
            <div className="row justify-content-center justify-content-md-start">
              <div className="col-12 col-sm-7 col-lg-4 my-2">
                <label>
                  <strong>Email</strong>
                </label>

                <input
                  type="email"
                  className="form-control"
                  defaultValue={emailAddress}
                  name="emailAddress"
                  onChange={(event) => {
                    handleInputChange(event);
                  }}
                />

                {showErrors && !mandatoryFields.emailAddress && (
                  <div className="alert alert-danger mt-1" role="alert">
                    <MdWarning className="mi-18" /> Email is required
                  </div>
                )}
              </div>
              <div className="col-12 col-sm-7 col-lg-4 my-2">
                <label>
                  <strong>Mobile</strong>
                </label>

                <input
                  type="text"
                  className="form-control"
                  defaultValue={mobile}
                  name="mobile"
                  onChange={(event) => {
                    handleInputChange(event);
                  }}
                />

                {showErrors && !mandatoryFields.mobile && (
                  <div className="alert alert-danger mt-1" role="alert">
                    <MdWarning className="mi-18" /> Mobile is required
                  </div>
                )}
              </div>
              <div className="col-12 col-sm-7 col-lg-4 my-2">
                <label>
                  <strong>Phone (other)</strong>
                </label>

                <input
                  type="text"
                  className="form-control"
                  defaultValue={phone}
                  name="phone"
                  onChange={(event) => {
                    handleInputChange(event);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {showErrors && (
          <div className="row text-center text-md-left mt-5 mb-n5">
            <div className="col-12 col-lg-6">
              <div className="alert alert-danger" role="alert">
                <MdWarning className="mi-18" /> You must complete all mandatory fields above to continue
              </div>
            </div>
          </div>
        )}

        <div className="row text-center text-md-left mt-5 ">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="row">
              <div className="col-6">
                <div className="btn btn-primary" onClick={() => navigateBack("part-exchange")}>
                  Back
                </div>
              </div>
              <div className="col-6">
                <div className="btn btn-primary" onClick={() => navigateNext("summary")}>
                  Next
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
