import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import getSize from "viewport-size";

import { Context } from "../context/context";
import EventImage from "../assets/images/event-image-alt.png";

const Congratulations = () => {
  const {
    state: {
      data: { details },
    },
    dispatch,
  } = useContext(Context);

  const [detailsFields, setDetailsFields] = useState(JSON.parse(localStorage.getItem("details")) || details);

  let history = useHistory();

  const navigateNext = (slug) => {
    history.push(slug);
  };

  // This useEffect will run on first load to check what is in localstorage and populate our context with it
  useEffect(() => {
    const details = localStorage.getItem("details");
    if (details) {
      dispatch({ type: "UPDATE_ALL_DETAILS", data: JSON.parse(details) });
      setDetailsFields(details);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect will set the data into localstorage on selection change
  useEffect(() => {
    setDetailsFields(details);
    localStorage.setItem("details", JSON.stringify(details));
  }, [details]);

  return (
    <div className="card border-0 bg-transparent mb-5 my-md-0 mb-0 mb-md-5">
      <div className="card-body">
        <div className="row align-items-start">
          <div className="col-12 col-md-8 order-2 order-md-1 text-center text-md-left my-4 my-md-0">
            <h1 className="page-title mb-0 text-white">
              Welcome{" "}
              <strong>
                {detailsFields.title} {detailsFields.lastName}
              </strong>{" "}
              to the <strong>BMW Priority Loyalty Programme</strong>
            </h1>
          </div>
          <div className="col-12 col-md-4 order-1 order-md-2">

          </div>
        </div>

        <div className="row text-center text-md-left justify-content-between mt-3">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4 ">
                <p className="text-white">Congratulations, you’ve been selected as a Priority Customer to choose how you wish to buy your next vehicle.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4 ">
                <p className="text-white">We have pleasure in offering you a Priority Purchase Experience, which allows you to choose when and how you want to buy your next vehicle - putting you in the driving seat.
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-6 col-lg-4 ">
                <div className="btn btn-outline-light" onClick={() => navigateNext("welcome")}>
                  Next
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row text-center text-md-left mt-5 ">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="row">
              <div className="col-12"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
