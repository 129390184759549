import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import getSize from "viewport-size";
import { MdWarning } from "react-icons/md";

import { Context } from "../context/context";
import EventImage from "../assets/images/event-image.png";

const Selection = () => {
  let history = useHistory();
  const {
    state: {
      data: { details, selection },
    },
    dispatch,
  } = useContext(Context);

  const [detailsFields, setDetailsFields] = useState(JSON.parse(localStorage.getItem("details")) || details);
  const [selectionFields, setSelectionFields] = useState(JSON.parse(localStorage.getItem("selection")) || selection);
  const [mandatoryFields, setmandatoryFields] = useState({ preferences: false, models: false });
  const [showErrors, setShowErrors] = useState(false);

  const navigateNext = (slug) => {
    const { preferences, models } = mandatoryFields;
    //First check to see if fields are completed
    if (preferences && models) {
      // Set the layout to completed and then
      dispatch({ type: "SET_LAYOUT_VISITED", slug: "selection" });
      history.push(slug);
    } else {
      //set page error  to true
      setShowErrors(true);
    }
  };

  const navigateBack = (slug) => {
    // First set the layout to completed and then
    // dispatch({ type: "SET_LAYOUT_VISITED", slug: "welcome" });
    history.push(slug);
  };

  const updateModels = (title) => {
    dispatch({ type: "UPDATE_SELECTION_MODEL", title });
  };

  const updatePreferences = (title) => {
    dispatch({ type: "UPDATE_SELECTION_PREFERENCE", title });
  };

  // This useEffect will run on first load to check what is in localstorage and populate our context with it
  useEffect(() => {
    const details = localStorage.getItem("details");
    const selection = localStorage.getItem("selection");

    if (details) {
      dispatch({ type: "UPDATE_ALL_DETAILS", data: JSON.parse(details) });
      setDetailsFields(details);
    }

    if (selection) {
      dispatch({ type: "UPDATE_ALL_SELECTIONS", data: JSON.parse(selection) });
      setSelectionFields(selection);
    }

    // Scroll to top on mobile screens only page load
    if (getSize.getWidth() < 575) {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 420,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    } else {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect will set the data into localstorage on selection change
  useEffect(() => {
    const { preferences, models } = mandatoryFields;

    setDetailsFields(details);
    localStorage.setItem("details", JSON.stringify(details));

    setSelectionFields(selection);
    localStorage.setItem("selection", JSON.stringify(selection));

    if (preferences && models) {
      // Set the layout to completed and then
      setShowErrors(false);
    }
  }, [details, selection, mandatoryFields]);

  // This useEffect will check whether any of the fields has been selected and set the button visibility
  useEffect(() => {
    const preferenceIsEntered = selectionFields.preferences.some((element) => {
      return element.selected === true;
    });
    const modelIsEntered = selectionFields.models.some((element) => {
      return element.selected === true;
    });

    setmandatoryFields((prevState) => {
      return { ...prevState, preferences: preferenceIsEntered, models: modelIsEntered };
    });
  }, [selectionFields]);

  return (
    <div className="card border-0 mb-5 my-md-0 mb-0 mb-md-5">
      <div className="card-body">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-8 order-2 order-md-1 text-center text-md-left my-4 my-md-0">
            <h1 className="page-title mb-0">Please select your model(s) of interest</h1>
          </div>
          <div className="col-8 col-md-4 order-1 order-md-2">

          </div>
        </div>

        <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-3">
          <div className="col-12 mb-3">
            <h3 className="page-sub-title">Choose your vehicle preference(s)</h3>
          </div>

          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  {selectionFields.preferences.map((preference) => {
                    return (
                      <div className="col-12 col-md-4 col-lg-3" key={preference.title}>
                        <div className="radio mx-auto">
                          <label>
                            <input
                              onChange={() => {
                                updatePreferences(preference.title);
                              }}
                              type="checkbox"
                              name="contactMethods"
                              value={preference.value}
                              defaultChecked={preference.selected}
                            />
                            {preference.title}
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {showErrors && !mandatoryFields.preferences && (
                <div className="col-12">
                  <div className="alert alert-danger mt-1" role="alert">
                    <MdWarning className="mi-18" /> Vehicle preference is required
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row text-center text-md-left justify-content-center justify-content-md-start mt-5">
          <div className="col-12 mb-3">
            <h3 className="page-sub-title">Choose your model(s)</h3>
          </div>

          {showErrors && !mandatoryFields.models && (
            <div className="col-12 col-lg-6">
              <div className="alert alert-danger mt-1" role="alert">
                <MdWarning className="mi-18" /> Model is required
              </div>
            </div>
          )}
          <div className="col-12">
            <div className="row">
              {selectionFields.models.map((model) => {
                return (
                  <div className="col-12 col-md-6 col-lg-3 py-2" key={model.title} onClick={() => updateModels(model.title)}>
                    <div className={model.selected ? "card vehicle-select selected" : "card vehicle-select"}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <h5 className="font-weight-bold">{model.title}</h5>
                          </div>
                          <div className="col-12">
                            {" "}
                            <img src={model.image} alt={model.title} className="img-fluid" />
                          </div>
                          <div className="col-12 text-center mt-n2">
                            <div className={model.selected ? "vehicle-select-button selected" : "vehicle-select-button"}>{model.selected ? "Selected" : "Select"}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {showErrors && (
          <div className="row text-center text-md-left mt-5 mb-n5">
            <div className="col-12 col-lg-6">
              <div className="alert alert-danger" role="alert">
                <MdWarning className="mi-18" /> You must complete all mandatory fields above to continue
              </div>
            </div>
          </div>
        )}

        <div className="row text-center text-md-left mt-5 ">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="row">
              <div className="col-6">
                <div className="btn btn-primary" onClick={() => navigateBack("welcome")}>
                  Back
                </div>
              </div>
              <div className="col-6">
                <div className="btn btn-primary" onClick={() => navigateNext("finance")}>
                  Next
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Selection;
