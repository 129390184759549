const DataReducer = (state, action) => {
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  switch (action.type) {
    // Initial Reducers
      case "UPDATE_FROM_API":
      const data = action.payload.response;
      var end = parseInt(Date.now()/1000);
      var start = end;
      if(data.contract_end_date !== undefined){
          var parts = data.contract_end_date.split("/");
          var dt = new Date(parseInt(parts[2], 10),
              parseInt(parts[1], 10) - 1,
              parseInt(parts[0], 10));
          end = dt.getTime()/1000;
      }

      var expiring = false;

      if(end > start){
         var diff = end - start;
         console.log(diff);
         if(diff < 7776000){
             expiring = true;
         }
      }
      console.log(data);
      console.log(expiring);
      return {
        ...state,
          selection: {
              preferences: [
                  { title: "New", value: "New", selected: false },
                  { title: "Ex-Demo", value: "Ex-Demo", selected: false },
                  { title: "Used", value: "Used", selected: false },
              ],
              models: data.models,
          },
        partExchange: {
          ...state.partExchange,
          make: data.make || "None specified",
          model: data.model || "None specified",
          registration: data.vrm || "None specified",
          mileage: data.mileage || "None specified",
          transmission: data.transmission || "None specified",
          fuel: data.fuel_type || "None specified",
          colour: data.colour || "None specified",
          contractStartDate: data.contract_start_date || "None specified",
          contractEndDate: data.contract_end_date || "None specified",
          monthlyPayment: data.monthly_payment || "None specified",
        },
        details: {
          ...state.details,
          title: data.title || "",
          firstName: capitalize(data.first_name.toLowerCase()) || "",
          lastName: capitalize(data.last_name.toLowerCase()) || "",
          emailAddress: data.email || "",
          mobile: data.mobile || "",
          phone: data.telephone1 || "",
          finance: data.finance || "",
          branchTradingName: data.participating_branch.trading_name || "",
          branchAddress1: data.participating_branch.address_1 || "",
          branchAddress2: data.participating_branch.address_2 || "",
          branchAddress3: data.participating_branch.address_3 || "",
          branchPostcode: data.participating_branch.postcode || "",
          branchTelephone: data.participating_branch.telephone || "",
          branchContactEmail: data.participating_branch.contact_email || "",
          branchToEmail: data.participating_branch.to_email || "",
          branchFromEmail: data.participating_branch.from_email || "",
          finance_expiring: expiring,
          dealerName: capitalize(data.event.dealer.name.toLowerCase()) || "",

        },
      };

    // End of Initial Reducers

    // Selection Reducers
    case "UPDATE_ALL_SELECTIONS":
      return { ...state, selection: action.data };
    case "UPDATE_SELECTION_PREFERENCE":
      const updatedPreferences = state.selection.preferences.map((item) => {
        if (item.title === action.title) {
          return { ...item, selected: !item.selected };
        } else {
          return item;
        }
      });
      return { ...state, selection: { ...state.selection, preferences: [...updatedPreferences] } };
    case "UPDATE_SELECTION_MODEL":
      const updatedModels = state.selection.models.map((item) => {
        if (item.title === action.title) {
          return { ...item, selected: !item.selected };
        } else {
          return item;
        }
      });
      return { ...state, selection: { ...state.selection, models: [...updatedModels] } };
    // End of Selection Reducers

    // Finance Reducers
    case "UPDATE_ALL_FINANCES":
      return { ...state, finance: action.data };
    case "UPDATE_FINANCE_BUDGET":
      const updatedBudgets = state.finance.budgets.map((item) => {
        if (item.title === action.title) {
          return { ...item, selected: true };
        } else {
          return { ...item, selected: false };
        }
      });
      return { ...state, finance: { ...state.finance, budgets: [...updatedBudgets] } };
    case "UPDATE_FINANCE_MILEAGE":
      const updatedMileages = state.finance.mileages.map((item) => {
        if (item.title === action.title) {
          return { ...item, selected: true };
        } else {
          return { ...item, selected: false };
        }
      });
      return { ...state, finance: { ...state.finance, mileages: [...updatedMileages] } };

    case "UPDATE_FINANCE_DEPOSIT":
      return { ...state, finance: { ...state.finance, deposit: action.data } };
    // End of Finance Reducers

    // PartExchange Reducers
    case "UPDATE_ALL_PARTEXCHANGE":
      return { ...state, partExchange: action.data };

    case "UPDATE_PARTEXCHANGE_CONDITION":
      const updatedCondition = state.partExchange.conditions.map((item) => {
        if (item.title === action.title) {
          return { ...item, selected: true };
        } else {
          return { ...item, selected: false };
        }
      });
      return { ...state, partExchange: { ...state.partExchange, conditions: [...updatedCondition] } };
    case "UPDATE_OWNERSHIP":
      const updateOwnership = state.partExchange.ownerships.map((item) => {
          if (item.title === action.title) {
              return { ...item, selected: true };
          } else {
              return { ...item, selected: false };
          }
      });
      return { ...state, partExchange: { ...state.partExchange, ownerships: [...updateOwnership] } };
    case "UPDATE_PARTEXCHANGE_MILEAGE":
      return { ...state, partExchange: { ...state.partExchange, currentMileage: action.data } };

    case "UPDATE_PARTEXCHANGE_COMMENTS":
      return { ...state, partExchange: { ...state.partExchange, comments: action.data } };
    // End of PartExchange Reducers

    // Details Reducers
    case "UPDATE_ALL_DETAILS":
      return { ...state, details: action.data };
    // End of Details Reducers
    case "UPDATE_DETAIL":
      const updatedDetails = { ...state.details, [action.data.name]: action.data.value };
      return { ...state, details: updatedDetails };
    // End of Details Reducers

    // Summary Reducers
    case "UPDATE_ALL_SUMMARY":
      return { ...state, summary: action.data };

    case "UPDATE_SUMMARY_CONTACTMETHOD":
      const updatedContactMethod = state.summary.contactMethods.map((item) => {
        if (item.title === action.title) {
          return { ...item, selected: !item.selected };
        } else {
          return item;
        }
      });
      return { ...state, summary: { ...state.summary, contactMethods: [...updatedContactMethod] } };

      case "UPDATE_APPOINTMENT":
          const updatedAppointment = state.summary.appointmentMethods.map((item) => {
              if (item.title === action.title) {
                  return { ...item, selected: !item.selected };
              } else {
                  return item;
              }
          });
          return { ...state, summary: { ...state.summary, appointmentMethods: [...updatedAppointment] } };


      case "UPDATE_SUMMARY_CONTACTTIME":
      const updatedContactTime = state.summary.contactTimes.map((item) => {
        if (item.title === action.title) {
          return { ...item, selected: !item.selected };
        } else {
          return item;
        }
      });
      return { ...state, summary: { ...state.summary, contactTimes: [...updatedContactTime] } };

    case "UPDATE_SUMMARY_CONTACTDAY":
      const updatedContactDay = state.summary.contactDays.map((item) => {
        if (item.title === action.title) {
          return { ...item, selected: !item.selected };
        } else {
          return item;
        }
      });
      return { ...state, summary: { ...state.summary, contactDays: [...updatedContactDay] } };
    // End of Summary Reducers

    // Appointment Reducers
    case "UPDATE_ALL_APPOINTMENTS":
      return { ...state, appointment: action.data };

    // case "UPDATE_APPOINTMENT_SELECTED_MODE":
    //   return { ...state, appointment: { ...state.appointment, SelectedMode: action.title } };

    case "UPDATE_APPOINTMENT_MODE":
      const updatedModes = state.appointment.modes.map((item) => {
        if (item.title === action.title) {
          return { ...item, selected: true };
        } else {
          return { ...item, selected: false };
        }
      });
      return { ...state, appointment: { ...state.appointment, modes: [...updatedModes] } };

    case "UPDATE_APPOINTMENT_LOCATION":
      const updatedLocation = state.appointment.modes.map((mode) => {
        return {
          ...mode,
          locations: mode.locations.map((location) => {
            if (location.title === action.title) {
              return { ...location, selected: true };
            } else {
              return { ...location, selected: false };
            }
          }),
        };
      });
      return { ...state, appointment: { ...state.appointment, modes: [...updatedLocation] } };

    // End of Appointment Reducers

    case "LOGOUT":
      return {
        ...state,
        welcome: {},
        selection: {
          preferences: [
            { title: "New", value: "New", selected: false },
            { title: "Ex-Demo", value: "Ex-Demo", selected: false },
            { title: "Used", value: "Used", selected: false },
          ],
          models: [
            {
              title: "up! 3 door",
              image: "up-3door.png",
              selected: false,
            },
            { title: "up! 5 door", image: "up-5door.png", selected: false },
            { title: "e-up!", image: "e-up.png", selected: false },
            { title: "Polo", image: "polo.png", selected: false },
          ],
        },
        finance: {
          budgets: [
            { title: "£100 - £200", value: "£100 - £200", selected: false },
            { title: "£200 - £300", value: "£200 - £300", selected: false },
            { title: "£300 - £400", value: "£300 - £400", selected: false },
            { title: "£400 - £500", value: "£400 - £600", selected: false },
            { title: "£500 - £600", value: "£600 - £700", selected: false },
            { title: "£700+", value: "£700+", selected: false },
          ],
          mileages: [
            { title: "5,000", value: "5,000", selected: false },
            { title: "10,000", value: "10,000", selected: false },
            { title: "15,000", value: "15,000", selected: false },
            { title: "20,000", value: "20,000", selected: false },
            { title: "25,000", value: "25,000", selected: false },
            { title: "30,000", value: "30,000", selected: false },
          ],
          deposit: "",
        },
        details: {
          title: "",
          firstName: "",
          lastName: "",
          emailAddress: "",
          mobile: "",
          phone: "",
          finance: "",
          branchName: "",
          branchAddress1: "",
          branchAddress2: "",
          branchAddress3: "",
          branchPostcode: "",
          branchTradingName: "",
          branchTelephone: "",
          branchContactEmail: "",
          branchToEmail: "",
          branchFromEmail: "",
        },
        partExchange: {
          make: "",
          model: "",
          registration: "",
          mileage: "",
          transmission: "",
          fuel: "",
          colour: "",
          contractStartDate: "",
          contractEndDate: "",
          monthlyPayment: "",
          conditions: [
            { title: "Good", value: "Good", selected: false },
            { title: "Average", value: "Average", selected: false },
            { title: "Poor", value: "Poor", selected: false },
          ],
          currentMileage: "",
          comments: "",
          ownerships: [
              { title: "I own this vehicle", value: "I own this vehicle", selected: false },
              { title: "I no longer own this vehicle", value: "I no longer own this vehicle", selected: false },
          ],
        },
        summary: {
         appointmentMethods: [
                { title: "Showroom", value: "Showroom", selected: false },
                { title: "Home Visit", value: "Home Visit", selected: false },
                { title: "Video Call", value: "Video Call", selected: false },
             { title: "Telephone", value: "Telephone", selected: false },
            ],
          contactMethods: [
            { title: "Email", value: "Email", selected: false },
            { title: "Sms", value: "Sms", selected: false },
            { title: "Telephone", value: "Telephone", selected: false },
          ],
          contactTimes: [
            { title: "Morning", value: "Morning", selected: false },
            { title: "Afternoon", value: "Afternoon", selected: false },
          ],
          contactDays: [
            { title: "Monday", value: "Monday", selected: false },
            { title: "Tuesday", value: "Tuesday", selected: false },
            { title: "Wednesday", value: "Wednesday", selected: false },
            { title: "Thursday", value: "Thursday", selected: false },
            { title: "Friday", value: "Friday", selected: false },
            { title: "Saturday", value: "Saturday", selected: false },
            { title: "Sunday", value: "Sunday", selected: false },
          ],
        },
        confirmation: {},
      };

    default:
      return state;
  }
};

export default DataReducer;
